import React from 'react';
import './App.css';

export const HowToUse = () => {
    return (
    <>
    <div className="howtouse-container">
    <h2>How to use</h2>
    <ol>
        <li>Enter the first name of the person to wish a happy birthday.</li>
        <li>Enter the message you have for them. Make sure to have less than 5 words per line. Break it into multiple lines if needed.</li>
        <li>Hit Submit. Hopefully, you get a link. Open the link and verify that it actually wishes the person with the message you wrote.</li>
        <li>Send the link to the person. It is mobile friendly too. 😊</li>
    </ol>
    {/* <h2>Support</h2>
    <ul>
        <li>If you can, consider <a href="https://www.buymeacoffee.com/hitiks" target='_blank' rel="noreferrer">buying me a coffee</a>. It helps. 🤗</li>
    </ul> */}
    </div>
    </>
    );
}