import { PrimaryButton, Spinner, Stack, TextField } from '@fluentui/react';
import axios, { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import './App.css';
import { HowToUse } from './HowToUse';

export const Home = () => {
    const [hasNameError, setHasNameError] = useState<boolean>(true);
    const [hasMessageError, setHasMessageError] = useState<boolean>(true);
    const [name, setName] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [processingErrorOccured, setProcessingErrorOccurred] = useState<boolean>(false);
    const [link, setLink] = useState<string>('');

    const getNameErrorMessage = (value: string) => {
        if(link.length !== 0) {
            return '';
        }

        if (value.length === 0) {
            setHasNameError(true);
            return 'Name should not be empty'
        }
    
        if (value.length > 20) {
            setHasNameError(true);
            return 'Input length should be less than 20 characters'
        }

        setHasNameError(false);
        return '';
    };

    const getMessageErrorMessage = (value: string) => {
        if(link.length !== 0) {
            return '';
        }

        if (value.length < 1) {
            setHasMessageError(true);
            return 'Message length should be more than 100 characters';
        }
    
        if (value.length > 1500) {
            setHasMessageError(true);
            return 'Message length should be less than 1500 characters';
        }
    
        setHasMessageError(false);
        return '';
    };

    const onNameChange = (ev: React.FormEvent, newName?: string) => {
        setName(newName || '');
    };

    const onMessageChange = (ev: React.FormEvent, newMessage?: string) => {
        setMessage(newMessage || '');
    };

    const submit = async () => {
        setProcessing(true);
        setLink('');
        setProcessingErrorOccurred(false);
        try {
            const response: AxiosResponse = await axios.post('https://birthdaybackend.azurewebsites.net/api/birthday',{ document: { name: name, message: message }});
            setLink(`https://livelongandprosper.netlify.app?id=${response.data.documentResponse._id}`);
            setName('');
            setMessage('');
        } catch (error) {
            setLink('');
            setProcessingErrorOccurred(true);
        } finally {
            setProcessing(false);
        }
    }

    return (
        <div className='home'>
            <Stack tokens={ stackTokens} styles={stackStyles}>
                <TextField 
                    label="First name"
                    value={name}
                    onGetErrorMessage={getNameErrorMessage}  
                    validateOnLoad={false}
                    onChange={onNameChange} 
                    disabled={processing}/>
                <TextField 
                    autoAdjustHeight 
                    label='Message' 
                    value={message}
                    multiline 
                    onGetErrorMessage={getMessageErrorMessage}  
                    validateOnLoad={false}
                    onChange={onMessageChange}
                    disabled={processing} />
                <PrimaryButton text='Submit' onClick={submit} disabled={ hasNameError || hasMessageError }/>
                { processingErrorOccured && <p className='error'>An error occurred. Please try again later.</p>}
                { processing && <Spinner />}
                { link.length > 0 && <a href={link} target='_blank' rel='noreferrer'>{link}</a>}
                <HowToUse />
            </Stack>
        </div>
    );
}

const stackTokens = { 
    childrenGap: '2vh' 
};

const stackStyles = { 
    root: { 
        width: '50vw' 
    } 
};
