import React from 'react';
import './App.css';
import { Home } from './Home';

function App() {
  const params = window.location.search.split('=');
  if(params.length < 2 || params[1] !== 'livelong') {
      return (
        <div>
            <h1>An error occurred. Please check the URL. If the error continues, try again later.</h1>
        </div>
      );
  }
  return (
    <Home />
  );
}

export default App;
